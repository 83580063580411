import { Container, Navbar, Nav } from "react-bootstrap";
import useBreakpoint from "../plugins/breakpoint";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { $t, t_key } from "../plugins/i18n";
import React from "react";
import { Setting } from "../models/settings";
import { SessionService } from "../services/session-service";
import Constants from "Constants";
import classNames from "classnames";
import { InterfaceControls } from "./InterfaceControls";

export type TopMenuProps = {
  theme: string,
  languages: string[]
}

enum Language {
  Est = "et",
  En = "en",
}

const TopMenu = (props: TopMenuProps) => {
  const isMobile = useBreakpoint();
  const { i18n } = useTranslation();
  const [settings, setSettings] = React.useState<Setting[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const getAccessabilityUrl = () => {
    const key = props.theme + "_" + i18n.language;
    const setting = settings.find(x => x.key.toLowerCase().indexOf(key.toLowerCase()) != -1);
    if (setting) {
      return setting.value;
    }
    return '#'
  }

  const loadSettings = async () => {
    const _settings = await SessionService.getAccessabilitySettings();
    setSettings(_settings);
  }

  const goToAccessability = () => {
    const url = getAccessabilityUrl();
    if (url != '#') {
      window?.open(url, '_blank')?.focus();
    }
  }

  const renderLangView = (): JSX.Element => {
    var languageToChangeTo = Language.Est;
    var descBy = $t(t_key.menu.change_language);

    switch (i18n.language) {
      case Language.En: {
        languageToChangeTo = Language.Est
        descBy = $t(t_key.menu.change_language_to_est)
        break;
      }
      case Language.Est: {
        languageToChangeTo = Language.En
        descBy = $t(t_key.menu.change_language_to_eng)
        break;
      }
      default: {
        languageToChangeTo = Language.Est
        descBy = $t(t_key.menu.change_language_to_est)
        break;
      }
    }

    const changeLanguage = (): void => {
      i18n.changeLanguage(languageToChangeTo);
      
      // Update URL query with new language
      const newParams = { ...Object.fromEntries(searchParams), lng: languageToChangeTo };
      setSearchParams(newParams);
    }

    return (
      <>
        <Nav.Link
          onClick={changeLanguage}
          role="button"
          aria-describedby="language-desc"
        >
          {$t(t_key.language.short + languageToChangeTo)}
        </Nav.Link>

        <span id="language-desc" style={{display: "none"}}>
          {descBy}
        </span>
      </>
    );
  }

  React.useEffect(() => {
    loadSettings();
  },[]);

  return (
    <>
      <a href={`#${Constants.MAIN_CONTENT}`} className="position-absolute top-0 start-0 visually-hidden-focusable" style={{zIndex: 4}}>
        {$t(t_key.buttons.go_to_main_content)}
      </a>
      <Navbar>
        <Container className="justify-content-between align-items-center">
          <Navbar.Brand aria-hidden>
            <img aria-hidden tabIndex={-1} className={
              classNames("master-logo",
                {"master-logo--rara": props.theme === "rara" && !isMobile},
                {"master-logo--rara-mobile": props.theme === "rara" && isMobile},
                {"master-logo--mirko": props.theme !== "rara" && !isMobile},
                {"master-logo--mirko-mobile": props.theme !== "rara" && isMobile},
              )}
              alt={props.theme == "rara" ? "RaRa logo" : "MiRKO logo"} 
            />
          </Navbar.Brand>

          <div>
            <Nav className="me-auto lang-menu">
              <InterfaceControls isMobile={isMobile} />

              {renderLangView()}

              <Nav.Link 
                onClick={() => goToAccessability()}
                role="button"
              >
                <img className="accessability-img" 
                  alt={$t(t_key.login_view.accessability)} 
                /> 
              </Nav.Link>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>);
}

TopMenu.displayName = "TopMenu"; //for chrome to show name instead of anonymous.

export default TopMenu;
